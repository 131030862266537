import React from "react";
import b from './App.module.scss'
import {Container} from "@chakra-ui/react";
import {Header} from "../header/Header";
import Form from "../content/Form";

const App = () => {
  return (
    <>
      <div className={b.appBg}></div>
      <Container maxW='container.lg' className={b.app}>
        <Header/>
        <Form/>
      </Container>
    </>
  );
}

export default App;
