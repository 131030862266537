import * as R from 'ramda';
import {getFormState} from "../../module-state-selectors";

export const getEvents = R.pipe(getFormState, R.prop('events'));
export const getIsCreatingAccreditation = R.pipe(getFormState, R.prop('isCreatingAccreditation'));
export const getIsFetchingEvents = R.pipe(getFormState, R.prop('isFetchingEvents'));
export const getIsFetchingSignature = R.pipe(getFormState, R.prop('isFetchingSignature'));
export const getSignature = R.pipe(getFormState, R.prop('signature'));
export const getOrderValues = R.pipe(getFormState, R.prop('values'))
export const getCategories = R.pipe(
  getEvents,
  R.ifElse(
    R.empty,
    R.pipe(
      R.map(
        R.pipe(
          R.prop('categories'),
          R.map(
            R.applySpec({
              name: R.prop('categoryName'),
              id: R.prop('categoryId'),
            }),
          ),
        ),
      ),
      R.mergeAll,
      R.values,
    ),
    R.always(null),
  ),
)
