import * as R from 'ramda';

const handleError = error => R.pipe(
  R.path(['response', 'data']),
  R.applySpec({
    code: R.prop('code'),
    message: R.prop('message'),
  }),
)(error);

export default handleError;
