import * as R from 'ramda';
import {handleActions} from 'redux-actions';
import {combineReducers} from "redux";
import {
  RECEIVE_CREATE_ACCREDITATION,
  RECEIVE_EVENTS,
  RECEIVE_SIGNATURE,
  RECEIVE_SIGNATURE_FAILED,
  REQUEST_CREATE_ACCREDITATION,
  REQUEST_CREATE_ACCREDITATION_FAILED,
  REQUEST_EVENTS,
  REQUEST_EVENTS_FAILED,
  REQUEST_SIGNATURE,
  SET_FORM_FIELD,
} from "./actions";

const values = handleActions(
  {
    [SET_FORM_FIELD]: (state, {payload}) => ({
      ...state,
      [payload.name]: payload.value
    })
  }, {
    type: 'onetime',
    event: '',
    category: '',
    lastname: '',
    lastnameInternational: '',
    name: '',
    nameInternational: '',
    patronymic: '',
    patronymicInternational: '',
    organization: '',
    photo: '',
    phone: '',
    otp: '',
    signature: '',
    email: '',
    comment: '',
  },
);

const events = handleActions(
  {
    [RECEIVE_EVENTS]: (_, {payload}) => payload
  },
  null
);

const isFetchingEvents = handleActions(
  {
    [REQUEST_EVENTS]: R.T,
    [RECEIVE_EVENTS]: R.F,
    [REQUEST_EVENTS_FAILED]: R.F
  },
  false,
);

const eventsError = handleActions(
  {
    [REQUEST_EVENTS]: () => null,
    [REQUEST_EVENTS_FAILED]: (_, {payload}) => payload
  },
  null,
);

const signature = handleActions(
  {
    [RECEIVE_SIGNATURE]: (_, {payload}) => R.prop('signature')(payload)
  },
  null,
);

const isFetchingSignature = handleActions(
  {
    [REQUEST_SIGNATURE]: R.T,
    [RECEIVE_SIGNATURE]: R.F,
    [RECEIVE_SIGNATURE_FAILED]: R.F
  },
  false,
);

const signatureError = handleActions(
  {
    [REQUEST_SIGNATURE]: () => null,
    [RECEIVE_SIGNATURE_FAILED]: (_, {payload}) => payload
  },
  null,
);

const isCreatingAccreditation = handleActions(
  {
    [REQUEST_CREATE_ACCREDITATION]: R.T,
    [RECEIVE_CREATE_ACCREDITATION]: R.F,
    [REQUEST_CREATE_ACCREDITATION_FAILED]: R.F
  },
  false,
);

const accreditationStatus = handleActions(
  {
    [RECEIVE_CREATE_ACCREDITATION]: (_, {payload}) => R.prop('status')(payload)
  },
  false,
);

const accreditationErrors = handleActions(
  {
    [REQUEST_CREATE_ACCREDITATION]: () => null,
    [REQUEST_CREATE_ACCREDITATION_FAILED]: (_, {payload}) => payload
  },
  null,
);


export default combineReducers({
  values,
  events,
  isFetchingEvents,
  eventsError,
  signature,
  isFetchingSignature,
  signatureError,
  isCreatingAccreditation,
  accreditationStatus,
  accreditationErrors
});
