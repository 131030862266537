import React from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack, Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid
} from "@chakra-ui/react";
import {Field, useFormikContext} from "formik";
import {connect} from "react-redux";
import * as R from "ramda";
import {getCategories, getEvents, getIsFetchingEvents} from "../../../../state/form";

const WorksSection = ({events, categories, isFetchingEvents}) => {
  const {values} = useFormikContext();
  return (
    <>
      <SimpleGrid columns={[1, 2]} spacing='20px'>
        <Box>
          <Field
            id="type"
            name="type"
          >
            {({field, form}) => (
              <FormControl>
                <FormLabel>Тип аккредитации</FormLabel>
                <RadioGroup tabIndex={4} name="type" defaultValue={field.value}>
                  <HStack spacing='24px'>
                    <Radio {...field} value='onetime'>Разовая</Radio>
                    <Radio {...field} value='season'>Сезонная</Radio>
                  </HStack>
                </RadioGroup>
                <FormErrorMessage>{form.errors.type}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt="5" columns={[1, 2]} spacing='20px'>
        <Box>
          <Field name="category" tabIndex={5}>
            {({field, form}) => (
              <FormControl isRequired isInvalid={form.errors.category && form.touched.category}>
                <FormLabel>Категория доступа</FormLabel>
                {isFetchingEvents ? (
                  <Select disabled name="category" placeholder='Загрузка'></Select>
                ) : (
                  <Select {...field}
                          onChange={field.onChange}
                          placeholder="Выберите категорию">
                    {!R.isNil(categories) ? (
                      categories.map(({id, name}) => (
                        <option key={id} value={id}>{name}</option>
                      ))
                    ) : (
                      <option disabled>Категорий не найдено</option>
                    )}
                  </Select>
                )}
                <FormErrorMessage>{form.errors.category}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt="5" columns={[1, 2]} spacing='20px'>
        <Box>
          <Field name="event" tabIndex={6}>
            {({field, form}) => (
              <FormControl isRequired isInvalid={form.errors.event && form.touched.event}>
                <FormLabel>{values.type === 'onetime' ? 'Мероприятие' : 'Сезон'}</FormLabel>
                {isFetchingEvents ? (
                  <Select disabled name="event" placeholder='Загрузка'></Select>
                ) : (
                  <Select
                    {...field}
                    onChange={field.onChange}
                    placeholder={values.type === 'onetime' ? 'Выберите матч' : 'Выберите сезон'}>
                    {!R.isNil(events) ? (
                      events.map(({id, name}) => (
                        <option key={id} value={id}>{name}</option>
                      ))
                    ) : (
                      <option disabled>{values.type === 'onetime' ? 'Мероприятий' : 'Активных сезонов'} не
                        найдено</option>
                    )}
                  </Select>
                )}
                <FormErrorMessage>{form.errors.event}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt={5} columns={[1, 2]} spacing='20px' tabIndex={7}>
        <Box>
          <Field
            id="organization"
            name="organization"
            type='text'
          >
            {({field, form}) => (
              <FormControl isRequired
                           isInvalid={form.errors.organization && form.touched.organization}>
                <FormLabel htmlFor="organization">Организация</FormLabel>
                <Input {...field}  />
                <FormErrorMessage>{form.errors.organization}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
      </SimpleGrid>
    </>
  );
}

const mapStateToProps = R.applySpec({
  events: getEvents,
  categories: getCategories,
  isFetchingEvents: getIsFetchingEvents,
});

export default connect(mapStateToProps)(WorksSection);
