import React from "react";
import {Field, useFormikContext} from "formik";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Stack
} from "@chakra-ui/react";
import {PhotoInput} from "./photo/PhotoInput";

export const PersonalSection = () => {
  const {setFieldValue} = useFormikContext();
  return (
    <>
      <SimpleGrid columns={[1, 2]} spacing='20px'>
        <Box>
          <Field
            id="lastname"
            name="lastname"
            type='text'
          >
            {({field, form}) => (
              <FormControl isRequired isInvalid={form.errors.lastname && form.touched.lastname}>
                <FormLabel htmlFor="lastname">Фамилия</FormLabel>
                <Input tabIndex={1} {...field} onChange={e => {
                  e.preventDefault();
                  const {value} = e.target;
                  setFieldValue("lastname", value);
                  setFieldValue("lastnameInternational", new CyrillicToTranslit().transform(value));
                }}/>
                <FormErrorMessage>{form.errors.lastname}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
        <Box>
          <Field
            id="lastnameInternational"
            name="lastnameInternational"
            type='text'
          >
            {({field, form}) => (
              <FormControl isRequired
                           isInvalid={form.errors.lastnameInternational && form.touched.lastnameInternational}>
                <FormLabel htmlFor="lastnameInternational">Фамилия латиницей</FormLabel>
                <Input {...field}  />
                <FormErrorMessage>{form.errors.lastnameInternational}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
        <Box>
          <Field
            id="name"
            name="name"
            type='text'
          >
            {({field, form}) => (
              <FormControl isRequired isInvalid={form.errors.name && form.touched.name}>
                <FormLabel htmlFor="name">Имя</FormLabel>
                <Input tabIndex={2} {...field} onChange={e => {
                  e.preventDefault();
                  const {value} = e.target;
                  setFieldValue("name", value);
                  setFieldValue("nameInternational", new CyrillicToTranslit().transform(value));
                }}/>
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
        <Box>
          <Field
            id="nameInternational"
            name="nameInternational"
            type='text'
          >
            {({field, form}) => (
              <FormControl isRequired isInvalid={form.errors.nameInternational && form.touched.nameInternational}>
                <FormLabel htmlFor="nameInternational">Имя латиницей</FormLabel>
                <Input {...field} />
                <FormErrorMessage>{form.errors.nameInternational}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
        <Box>
          <Field
            id="patronymic"
            name="patronymic"
            type='text'
          >
            {({field, form}) => (
              <FormControl isInvalid={form.errors.patronymic && form.touched.patronymic}>
                <FormLabel htmlFor="patronymic">Отчество</FormLabel>
                <Input tabIndex={3} {...field} onChange={e => {
                  e.preventDefault();
                  const {value} = e.target;
                  setFieldValue("patronymic", value);
                  setFieldValue("patronymicInternational", new CyrillicToTranslit().transform(value));
                }}/>
                <FormErrorMessage>{form.errors.patronymic}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
        <Box>
          <Field
            id="patronymicInternational"
            name="patronymicInternational"
            type='text'
          >
            {({field, form}) => (
              <FormControl isInvalid={form.errors.patronymicInternational && form.touched.patronymicInternational}>
                <FormLabel htmlFor="patronymicInternational">Отчество латиницей</FormLabel>
                <Input {...field} />
                <FormErrorMessage>{form.errors.patronymicInternational}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
      </SimpleGrid>

      <Stack mt="6" direction={['column', 'row']} spacing='14px'>
        <PhotoInput name="photo" />
      </Stack>
    </>
  );
}
