import React from "react";
import {Box, Stack, Text} from "@chakra-ui/react";
import logo from './assets/logo_23.png';

export const Header = () => {
  return (
    <Stack pt="10" pb="10" direction={['column', 'row']} spacing='15px'>
      <Box w='50px' h='50px'>
        <img src={logo} alt=""/>
      </Box>
      <Box w='200px' h='50px' color='white'>
        <Text fontSize='md' as='b'>Центр аккредитации
          «Локомотив-Кубань»</Text>
      </Box>
    </Stack>
  );
}
