import * as R from "ramda";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Field, useFormikContext} from "formik";
import {
  Box,
  Button,
  FormControl, FormErrorMessage, FormHelperText,
  FormLabel, HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement, PinInput, PinInputField, SimpleGrid
} from "@chakra-ui/react";
import {EmailIcon, PhoneIcon} from "@chakra-ui/icons";
import InputMask from "react-input-mask";
import {
  getIsFetchingSignature,
  getSignature,
  requestSignature
} from "../../../../state/form";
import Countdown from "react-countdown";

export const ContactsSection = ({signature, isFetchingSignature, requestSignature}) => {
  const {values, setFieldValue} = useFormikContext();
  const [disabledSendOtpButton, setDisabledSendOtpButton] = useState(true);
  const [disabledNewCode, setDisabledNewCode] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const buttonOtpHandler = () => {
    requestSignature(values.phone);
    setDisabledNewCode(true);
    setCountdown(Date.now() + 59000);
  };
  const onCompleteHandler = () => {
    setDisabledNewCode(false);
  }
  const onChangePhoneInputHandler = e => {
    e.preventDefault();
    const {value} = e.target;
    if (value.length === 18) {
      setDisabledSendOtpButton(false);
    } else {
      setDisabledSendOtpButton(true);
    }
    setFieldValue("phone", value);
  }
  useEffect(() => {
    setFieldValue('signature', signature);
  }, [signature])
  return (
    <>
      <SimpleGrid columns={[1, 2]} spacing='20px'>
        <Box>
          <Field
            id="phone"
            name="phone"
            type='tel'
          >
            {({field, form}) => (
              <FormControl isRequired isInvalid={form.errors.phone && form.touched.phone}>
                <FormLabel>Номер мобильного телефона</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<PhoneIcon color='gray.300'/>}
                  />
                  <Input {...field}
                         isReadOnly={disabledNewCode}
                         as={InputMask}
                         mask="+7 (999) 999-99-99"
                         maskChar={null}
                         onChange={onChangePhoneInputHandler}
                  />
                  <InputRightElement width='135px'>
                    <Button colorScheme='orange'
                            isLoading={isFetchingSignature}
                            disabled={disabledSendOtpButton || disabledNewCode}
                            h='1.75rem'
                            size='sm'
                            onClick={buttonOtpHandler}>
                      Получить код
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                {disabledNewCode && (
                  <FormHelperText>Новый код можно запросить повторно через <Countdown
                    renderer={props => <b>{props.seconds}</b>} onComplete={onCompleteHandler}
                    date={countdown}/> секунд
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Field>
        </Box>
        {values.signature && (
          <Box>
            <Field name="otp">
              {({field, form}) => (
                <FormControl isRequired isInvalid={form.errors.otp && form.touched.otp}>
                  <FormLabel>Код из смс</FormLabel>
                  <HStack>
                    <PinInput isInvalid={form.errors.otp && form.touched.otp}
                              otp={true}
                              onChange={value => {
                                setFieldValue("otp", value);
                              }}>
                      <PinInputField/>
                      <PinInputField/>
                      <PinInputField/>
                      <PinInputField/>
                    </PinInput>
                    <Input {...field} type='hidden'/>
                  </HStack>
                  <FormErrorMessage>{form.errors.otp}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Box>
        )}
      </SimpleGrid>
      <SimpleGrid mt="5" columns={[1, 2]} spacing='20px'>
        <Box>
          <Field
            id="email"
            name="email"
            type='email'
          >
            {({field, form}) => (
              <FormControl isRequired
                           isInvalid={form.errors.email && form.touched.email}>
                <FormLabel>E-mail (электронная почта)</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<EmailIcon color='gray.300'/>}
                  />
                  <Input {...field} />
                </InputGroup>
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
      </SimpleGrid>
    </>
  );
}

const mapStateToProps = R.applySpec({
  signature: getSignature,
  isFetchingSignature: getIsFetchingSignature,
});

const mapDispatchToProps = {
  requestSignature
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsSection);
