import {all, call, put, takeLatest} from 'redux-saga/effects';
import {apiRequestCreatAccreditation, apiRequestEvents, apiRequestSignature} from "./manager";
import {
  receiveCreateAccreditation, receiveCreateAccreditationFailed,
  receiveEvents,
  receiveSignature,
  receiveSignatureFailed, REQUEST_CREATE_ACCREDITATION, REQUEST_EVENTS,
  REQUEST_SIGNATURE, requestEventsFailed
} from "./actions";
import handleError from "../../utils/handleError";
import {createStandaloneToast} from "@chakra-ui/react"
const { toast } = createStandaloneToast()

const fetchAccreditationCreateStatusSaga = function* ({payload}) {
  const {values, setErrors, resetForm, setSubmitting} = payload;
  try {
    const accreditationStatus = yield call(apiRequestCreatAccreditation, values);
    yield put(receiveCreateAccreditation(accreditationStatus));
    toast({
      title: 'Заявка принята',
      description: 'Ваша заявка на аккредитацию успешно отправлена',
      position: 'top',
      status: 'success',
      duration: 7000,
      isClosable: true,
    });
    resetForm();
    setSubmitting(false);
  } catch (error) {
    const handledError = handleError(error);
    yield put(receiveCreateAccreditationFailed(handledError))
    if (handledError.code === 'FORM_INVALID') {
      const orderErrorsArray = JSON.parse(handledError.message)
      setErrors(orderErrorsArray);
    } else {
      toast({
        title: 'Возникла проблема',
        description: handledError.message,
        position: 'top',
        status: 'error',
        duration: 7000,
        isClosable: true,
      });
    }
    setSubmitting(false);
  }
}

const accreditationCreateStatusSaga = function* () {
  yield takeLatest(REQUEST_CREATE_ACCREDITATION, fetchAccreditationCreateStatusSaga);
}

const fetchEventsSaga = function* ({payload}) {
  try {
    const events = yield call(apiRequestEvents, payload);
    yield put(receiveEvents(events));
  } catch (error) {
    const handledError = handleError(error);
    yield put(requestEventsFailed(handledError))
  }
}

const eventsSaga = function* () {
  yield takeLatest(REQUEST_EVENTS, fetchEventsSaga);
}

const fetchSignatureSaga = function* ({payload}) {
  try {
    const signature = yield call(apiRequestSignature, payload);
    yield put(receiveSignature(signature));
  } catch (error) {
    const handledError = handleError(error);
    yield put(receiveSignatureFailed(handledError))
  }
}

const signatureSaga = function* () {
  yield takeLatest(REQUEST_SIGNATURE, fetchSignatureSaga);
}

const accreditationSaga = function* () {
  yield all([
    call(eventsSaga),
    call(signatureSaga),
    call(accreditationCreateStatusSaga),
  ]);
}

export default accreditationSaga;
