import * as R from 'ramda';
import {ENDPOINTS} from '../../api';
import axios from "axios";

// Запрашиваем мероприятия
const getEvents = response => R.pipe(
  R.prop('data'),
  R.map(
    R.applySpec({
      id: R.prop('eventId'),
      type: R.prop('eventType'),
      name: R.prop('eventName'),
      categories: R.prop('eventAccessCategories'),
    }),
  ),
)(response);

export const apiRequestEvents = (payload) => {
  return axios.get(payload === 'onetime' ? ENDPOINTS.getEvents : ENDPOINTS.getSeasons
  ).then(response => {
    return getEvents(response.data);
  })
    .catch(err => {
      throw err;
    });
}

// Запрашиваем сигнатуру
const getSignature = response => R.pipe(
  R.prop('data'),
  R.applySpec({
    signature: R.prop('signature'),
  }),
)(response);

export const apiRequestSignature = (payload) => {
  let data = new FormData();
  data.append('phone', payload);
  return axios.post(ENDPOINTS.sendVerificationCode, data
  ).then(response => {
    return getSignature(response.data);
  })
    .catch(err => {
      throw err;
    });
}

// Создаём заявку на аккредитацию
const getAccreditationCreateStatus = accreditation => R.pipe(
  R.applySpec({
    status: R.propEq('status', true),
  }),
)(accreditation);

export const apiRequestCreatAccreditation = (payload) => {
  let data = new FormData();
  Object.entries(payload).forEach(entry => {
    const [key, value] = entry;
    data.append(key, value)
  });
  return axios.post(ENDPOINTS.createAccreditation, payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  ).then(response => {
    return getAccreditationCreateStatus(response.data);
  })
    .catch(err => {
      throw err;
    });
}
