import * as R from "ramda";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Formik, useFormikContext} from "formik";
import * as Yup from "yup";
import {
  Box, Button,
  Heading,
  SimpleGrid, Text,
  VStack
} from "@chakra-ui/react";
import ContactsSection from "./sections/contacts/ContactsSection";
import {PersonalSection} from "./sections/personal/PersonalSection";
import WorksSection from "./sections/works/WorksSection";
import {createAccreditation, getIsCreatingAccreditation, getOrderValues, requestEvents} from "../../state/form";
import {CommentsSections} from "./sections/comments/CommentsSections";

const FILE_SIZE = 2 * 1024 * 1024;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
];

const Form = ({formValues, isCreatingAccreditation, requestEvents, createAccreditation}) => {
  const validationSchema = Yup.object({
    lastname: Yup.string().required('Обязательное поле'),
    lastnameInternational: Yup.string().required('Обязательное поле'),
    name: Yup.string().required('Обязательное поле'),
    nameInternational: Yup.string().required('Обязательное поле'),
    patronymic: Yup.string(),
    patronymicInternational: Yup.string(),
    organization: Yup.string().required('Обязательное поле'),
    category: Yup.string().required('Обязательное поле'),
    event: Yup.string().required('Обязательное поле'),
    phone: Yup.string().required('Обязательное поле').length(18, 'Необходимо ввести полный номер телефона'),
    otp: Yup.string().required('Обязательное поле'),
    email: Yup.string().email('Неверный адрес электронной почты').required('Обязательное поле'),
    photo: Yup.mixed()
      .test('fileSize', 'Ошибка! Загруженный файл более 2 Мб.', (value) => value === null || (value && value.size <= FILE_SIZE))
      .test(
        'fileFormat',
        'Файл имеет не поддерживаемый тип данных. Разрешено использовать следующие типы данных: jpg',
        (value) => value === null || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });
  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting, setErrors, resetForm}) => {
        createAccreditation({values, setErrors, resetForm, setSubmitting});
      }}
    >
      {({
          handleSubmit,
          isSubmitting,
        }) => (
        <form noValidate onSubmit={handleSubmit}>
          <FormObserver requestEvents={requestEvents}/>
          <Box w='100%' pt='2' pb="8">
            <Heading as='h1' mb="8" color="white">Заявка на аккредитацию</Heading>
            <VStack
              spacing={4}
              align='stretch'
            >
              <Box>
                <Heading mb="4" as='h3' size='md' color="white">Персональные данные</Heading>
                <PersonalSection/>
              </Box>
              <Box>
                <Heading mt="4" mb="4" as='h3' size='md' color="white">Рабочие данные</Heading>
                <WorksSection/>
              </Box>
              <Box>
                <Heading mt="4" mb="4" as='h3' size='md' color="white">Контактные данные</Heading>
                <ContactsSection/>
              </Box>
              <Box>
                <CommentsSections />
              </Box>
              <Box>
                <SimpleGrid mt="2" columns={[1, 2]} spacing='20px'>
                  <Button
                    size='lg'
                    mt={4}
                    colorScheme='whatsapp'
                    type='submit'
                    disabled={isSubmitting}
                    isLoading={isCreatingAccreditation}
                  >
                    Отправить заявку
                  </Button>
                  <Text mt={3} fontSize='sm' style={{lineHeight: 1.3}}>Нажимая кнопку «Отправить заявку», я подтверждаю,
                    что я ознакомлен и согласен с условиями <a href="https://lokobasket.com/confidence/" target="_blank"
                                                               rel="noreferrer"><u>политики обработки персональных
                      данных</u></a>.</Text>
                </SimpleGrid>
              </Box>
            </VStack>
          </Box>
        </form>
      )}
    </Formik>
  );
}

const FormObserver = ({requestEvents}) => {
  const {values} = useFormikContext();
  useEffect(() => {
    requestEvents(values.type)
  }, [requestEvents, values.type]);
};

const mapStateToProps = R.applySpec({
  formValues: getOrderValues,
  isCreatingAccreditation: getIsCreatingAccreditation,
});

const mapDispatchToProps = {
  requestEvents,
  createAccreditation
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
