import React, {useEffect, useState} from "react";
import {useField, useFormikContext} from "formik";
import photoPlaceholder from './assets/placeholder.png'
import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  FormControl, FormErrorMessage,
  FormHelperText, FormLabel, HStack,
  IconButton,
  Image,
  Input, Text
} from "@chakra-ui/react";
import {AddIcon, AttachmentIcon, DeleteIcon} from "@chakra-ui/icons";
import fileSize from "filesize";

export const PhotoInput = (props) => {
  const {setFieldValue, setFieldTouched} = useFormikContext()
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()
  const [field, meta] = useField(props);
  const {value, ...rest} = field;
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleBlur = () => {
    setFieldTouched(field.name)
  }
  const handleRemoveFile = () => {
    setFieldValue(field.name, "")
  }
  const onSelectFile = e => {
    const file = e.currentTarget.files[0];
    if (!file || file.length === 0) {
      setSelectedFile(undefined)
      setFieldValue(field.name, undefined)
      return
    }
    setFieldValue(field.name, file);
    setSelectedFile(file)
  }
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])
  return (
    <>
      <Box w='200px' h='267px' borderWidth='1px' borderRadius='lg' overflow='hidden'>
        <AspectRatio maxW='300px' ratio={3 / 4}>
          <Image src={field.value ? preview : photoPlaceholder} alt='Фотография' objectFit='cover'/>
        </AspectRatio>
      </Box>
      <Box w={{sm: 360}}>
        <FormControl isRequired isInvalid={meta.error && meta.touched}>
          <FormLabel htmlFor="photo">Фотография</FormLabel>
          {field.value ? (
            <>
              <UploadededPhoto onClick={handleRemoveFile} value={field.value}/>
            </>
          ) : (
            <>
              <ButtonGroup size='md' isAttached variant='outline' onClick={handleClick}>
                <Button>Загрузить фотографию</Button>
                <IconButton aria-label='Добавить фотографию' icon={<AddIcon/>}/>
              </ButtonGroup>
              <Input
                multiple={false}
                {...rest}
                {...props}
                type="file"
                hidden
                value={undefined}
                ref={hiddenFileInput}
                onChange={onSelectFile}
                onBlur={handleBlur}
              />
              <FormHelperText>
                На фотографии должно быть хорошо видно ваше лицо, чтобы мы могли верифицировать заявку.
                <br/> Фото не более 2 Мб. Тип файла: JPG
              </FormHelperText>
            </>
          )}
          {meta.touched && meta.error && (
            <FormErrorMessage>{meta.error}</FormErrorMessage>
          )}
        </FormControl>
      </Box>
    </>
  );
}

const UploadededPhoto = (props) => {
  const {
    onClick = () => {
    }
  } = props
  const {name, size} = props.value;
  const ext = name.split('.').pop();
  const fileWithoutExt = name.split('.').slice(0, -1).join('.');
  let fileName = name;
  if (fileWithoutExt.length > 20) {
    fileName = fileWithoutExt.substring(0, 8) + "..." + fileWithoutExt.substring(fileWithoutExt.length - 8) + "." + ext;
  }
  const sizeFile = fileSize.partial({base: 1, standard: "jedec"});
  const sizeFileFormatted = sizeFile(size);
  return (
    <div>
      <Text as='b'><AttachmentIcon /> {fileName}</Text>
      <HStack mt={2} spacing='24px'>
        <Box>
          <Text as='i' fontSize='sm'>{sizeFileFormatted}</Text>
        </Box>
        <Box>
          <Button size='xs' onClick={onClick} leftIcon={<DeleteIcon/>} colorScheme='red' variant='solid'>
            Удалить
          </Button>
        </Box>
      </HStack>
    </div>
  );
}
