import React from "react";
import {FormControl, FormErrorMessage, FormLabel, SimpleGrid, Textarea} from "@chakra-ui/react";
import {Field} from "formik";

export const CommentsSections = () => {
  return (
    <>
      <SimpleGrid mt="2" columns="1" spacing='20px'>
        <Field
          id="comment"
          name="comment"
        >
          {({field, form}) => (
            <FormControl isInvalid={form.errors.comment && form.touched.comment}>
              <FormLabel htmlFor="comment">Комментарий</FormLabel>
              <Textarea {...field} />
              <FormErrorMessage>{form.errors.comment}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </SimpleGrid>
    </>
  );
}
