import {createAction} from 'redux-actions';

export const REQUEST_EVENTS = 'form/REQUEST_EVENTS';
export const RECEIVE_EVENTS = 'form/RECEIVE_EVENTS';
export const REQUEST_EVENTS_FAILED = 'form/REQUEST_EVENTS_FAILED';
export const REQUEST_CREATE_ACCREDITATION = 'form/REQUEST_CREATE_ACCREDITATION';
export const RECEIVE_CREATE_ACCREDITATION = 'form/RECEIVE_CREATE_ACCREDITATION';
export const REQUEST_CREATE_ACCREDITATION_FAILED = 'form/REQUEST_CREATE_ACCREDITATION_FAILED';
export const REQUEST_SIGNATURE = 'form/REQUEST_SIGNATURE';
export const RECEIVE_SIGNATURE = 'form/RECEIVE_SIGNATURE';
export const RECEIVE_SIGNATURE_FAILED = 'form/RECEIVE_SIGNATURE_FAILED';
export const SET_FORM_FIELD = 'form/SET_FORM_FIELD';

export const requestEvents = createAction(REQUEST_EVENTS)
export const receiveEvents = createAction(RECEIVE_EVENTS)
export const requestEventsFailed = createAction(REQUEST_EVENTS_FAILED)
export const createAccreditation = createAction(REQUEST_CREATE_ACCREDITATION);
export const receiveCreateAccreditation= createAction(RECEIVE_CREATE_ACCREDITATION);
export const receiveCreateAccreditationFailed = createAction(REQUEST_CREATE_ACCREDITATION_FAILED);
export const requestSignature = createAction(REQUEST_SIGNATURE);
export const receiveSignature = createAction(RECEIVE_SIGNATURE);
export const receiveSignatureFailed = createAction(RECEIVE_SIGNATURE_FAILED);
