import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import configureStore from "./configureStore";
import {ChakraProvider, createStandaloneToast} from "@chakra-ui/react"
import App from "./containers/app/App";
import {extendTheme} from '@chakra-ui/react'

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const store = configureStore();
const theme = extendTheme({config})

const root = ReactDOM.createRoot(document.getElementById('root'));
const { ToastContainer } = createStandaloneToast();

root.render(
  <>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App/>
        <ToastContainer />
      </ChakraProvider>
    </Provider>
  </>
);

